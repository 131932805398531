<template>
  <v-container class="bg-color-theme">
    <v-row>
      <v-col cols="12" md="12">
        <div class="supplier-list-main font-Roboto">
          <div class="d-flex justify-space-between align-center mb-6">
            <v-select
              class="font-15px font-weight-500 sort-dropdown mr-4"
              dense
              solo
              hide-details
              :items="sortItems"
              item-text="value"
              item-value="id"
              v-model="sortOrder"
              outlined
              @change="getDataFromApi"
            ></v-select>
            <v-btn
              text
              :to="{ name: 'SupplierCreate' }"
              class="font-weight-400 primary--text font-14px px-0"
            >
              ＋ 新規作成
            </v-btn>
          </div>
          <v-skeleton-loader
            v-if="loading"
            type="list-item-three-line"
          ></v-skeleton-loader>
          <v-list v-else dense color="transparent" class="details-list mb-10">
            <v-list-item-group>
              <v-list-item
                v-for="store in getSuppliers"
                :key="store.id"
                class="item-list item-active"
                :disabled="!store || store.status == '2'"
                :to="{ name: 'SupplierDetails', params: { id: store.id } }"
                :class="
                  store.status == '0'
                    ? 'item-disabled'
                    : store.status == '2'
                    ? 'item-disabled connecting'
                    : ''
                "
              >
                <v-list-item-content class="py-2">
                  <v-list-item-title class="font-15px font-weight-regular">{{
                    store.display_name
                  }}</v-list-item-title>
                  <v-list-item-subtitle class="font-Roboto font-12px text-67">
                    {{ getCategoryName(store.category) }}
                  </v-list-item-subtitle>
                </v-list-item-content>
                <v-list-item-action>
                  <v-icon size="15" color="white">$rightArrow</v-icon>
                </v-list-item-action>
              </v-list-item>
            </v-list-item-group>
          </v-list>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "SupplierList",
  data() {
    return {
      loading: false,
      sortOrder: "desc",
      sortItems: [
        {
          id: "desc",
          value: "登録が新しい順"
        },
        {
          id: "asc",
          value: "登録が古い順"
        }
      ]
    };
  },
  created() {
    let this_this = this;
    this.$root.$off("shopChanged");
    this.$root.$on("shopChanged", function() {
      this_this.getDataFromApi();
    });
    this.getDataFromApi();
  },
  computed: {
    ...mapGetters(["getSuppliers"])
  },
  methods: {
    getDataFromApi() {
      this.loading = true;
      this.$store
        .dispatch("SUPPLIER_GET_ALL", {
          shop_id: this.$store.getters.getFirstShopObject.id,
          orderBy: this.sortOrder
        })
        .finally(() => {
          this.loading = false;
        });
    },
    getCategoryName(shopCategories) {
      let partnerCategoryNames = [];
      let category = "";
      shopCategories.forEach(catId => {
        this.$store.getters.getMasterData?.partners_category.map(
          (partners_category, key) => {
            if (partners_category.id == catId) {
              partnerCategoryNames.push(partners_category.value);
              category = partnerCategoryNames.join(" , ");
            }
          }
        );
      });
      return category;
    }
  }
};
</script>

<style lang="scss" scope src="./style.scss"></style>
